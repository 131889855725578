import Main from './pages/Main';
import Touchscreen from './pages/Touchscreen';
import GameForm from './pages/GameForm';
import MainSmall from './pages/MainSmall';
import TouchscreenSmall from './pages/TouchscreenSmall';

import NoMatch from './pages/NoMatch/NoMatch';

const routes = [
  {
    path: '/',
    component: Main,
    exact: true,
  },
  {
    path: 'touchscreen',
    component: Touchscreen,
    exact: true,
  },
  {
    path: 'm/touchscreen',
    component: TouchscreenSmall,
    exact: true,
  },
  {
    path: 'm',
    component: MainSmall,
    exact: true,
  },
  {
    path: 'registration-game',
    component: GameForm,
    exact: true,
  },
  {
    path: '*',
    component: NoMatch,
    exact: true,
  },
];

export default routes;
